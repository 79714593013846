import React from "react"
import PropTypes from "prop-types"

const strippedNestedString = (string) => {
  const split = string.split(/>(.*)</);
  return split[1];
}

const strippedString = (string) => {
  const split = string.split(/{(.*)}/);
  return split[1];
}

const getNestedElements = (string) => {
  return string.match(/\{.+?\}/g);
};

const Translate = ({ children, template, wrap, wrapWrap, noneMarkedWrap, className, nested }) => {
  const wrapChildren = wrap && [];
  const nestedElements = nested && getNestedElements(template);

  if (nested) {
    const split = template.split(/(\{.+?\})/);
    return (
      <span className={className}>
        {split.map((val, index) => {
          if (val[0] === "{") {
            const childIndex = Number(val.match(/\d+/)[0])
            const stripped = strippedString(val);
            const splitNested = stripped.match(/(<\d>[^<]*<\/\d>)/g);
            splitNested.map((val, index) => {
              const stripped = strippedNestedString(val);
              const childWithExtraProp = React.cloneElement(Array.isArray(children) ? children[childIndex - 1] : children, {
                children: [stripped],
                key: index,
              })
              if (wrap) {
                wrapChildren.push(childWithExtraProp);
              }
            });
            if (wrap && !wrapWrap) {
              return React.cloneElement(wrap, {
                children: wrapChildren,
                key: index
              })
            }
            if (wrap && wrapWrap) {
              return React.cloneElement(wrapWrap, {
                key: index,
                children: React.cloneElement(wrap, {
                  children: wrapChildren,
                  key: index
                })
              })
            }
          }
          else {
            if (val.length == 0) {
              return;
            }
            if (noneMarkedWrap) {
              const childWithExtraProp = React.cloneElement(noneMarkedWrap, {
                children: [val],
                key: index
              })
              return childWithExtraProp;
            } else {
              return val;
            }
          }
        })}
      </span>
    )
  }

  const split = template.split(/(<\d>[^<]*<\/\d>)/);

  return (
    <span className={className}>
      {split.map((val, index) => {
        if (val[0] === "<") {
          const childIndex = Number(val.match(/\d+/)[0])
          const stripped = strippedNestedString(val);

          const childWithExtraProp = React.cloneElement(Array.isArray(children) ? children[childIndex - 1] : children, {
            children: [stripped],
            key: index
          })
          if (wrap) {
            wrapChildren.push(childWithExtraProp);
          } else {
            return childWithExtraProp;
          }
        } else {
          if (val.length == 0) {
            return;
          }
          if (noneMarkedWrap) {
            const childWithExtraProp = React.cloneElement(noneMarkedWrap, {
              children: [val],
              key: index
            })
            return childWithExtraProp;
          } else {
            return val;
          }
        }
      })}
      {wrap && !wrapWrap && React.cloneElement(wrap, {
        children: wrapChildren
      })}
      {wrap && wrapWrap && React.cloneElement(wrapWrap, {
        children: React.cloneElement(wrap, {
          children: wrapChildren
        })
      })}
    </span>
  )
}

Translate.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Translate
