export const countries = {
  BD: {
    name: "Bangladesh",
    code: "880",
  },
  BE: {
    name: "Belgium",
    code: "32",
  },
  BF: {
    name: "Burkina Faso",
    code: "226",
  },
  BG: {
    name: "Bulgaria",
    code: "359",
  },
  BA: {
    name: "Bosnia and Herzegovina",
    code: "387",
  },
  BB: {
    name: "Barbados",
    code: "1",
  },
  WF: {
    name: "Wallis and Futuna",
    code: "681",
  },
  BL: {
    name: "Saint Barthelemy",
    code: "590",
  },
  BM: {
    name: "Bermuda",
    code: "1",
  },
  BN: {
    name: "Brunei",
    code: "673",
  },
  BO: {
    name: "Bolivia",
    code: "591",
  },
  BH: {
    name: "Bahrain",
    code: "973",
  },
  BI: {
    name: "Burundi",
    code: "257",
  },
  BJ: {
    name: "Benin",
    code: "229",
  },
  BT: {
    name: "Bhutan",
    code: "975",
  },
  JM: {
    name: "Jamaica",
    code: "1",
  },
  BV: {
    name: "Bouvet Island",
    code: "47",
  },
  BW: {
    name: "Botswana",
    code: "267",
  },
  WS: {
    name: "Samoa",
    code: "685",
  },
  BQ: {
    name: "Bonaire, Saint Eustatius and Saba ",
    code: "599",
  },
  BR: {
    name: "Brazil",
    code: "55",
  },
  BS: {
    name: "Bahamas",
    code: "1",
  },
  JE: {
    name: "Jersey",
    code: "44",
  },
  BY: {
    name: "Belarus",
    code: "375",
  },
  BZ: {
    name: "Belize",
    code: "501",
  },
  RU: {
    name: "Russia",
    code: "7",
  },
  RW: {
    name: "Rwanda",
    code: "250",
  },
  RS: {
    name: "Serbia",
    code: "381",
  },
  TL: {
    name: "East Timor",
    code: "670",
  },
  RE: {
    name: "Reunion",
    code: "262",
  },
  TM: {
    name: "Turkmenistan",
    code: "993",
  },
  TJ: {
    name: "Tajikistan",
    code: "992",
  },
  RO: {
    name: "Romania",
    code: "40",
  },
  TK: {
    name: "Tokelau",
    code: "690",
  },
  GW: {
    name: "Guinea-Bissau",
    code: "245",
  },
  GU: {
    name: "Guam",
    code: "1",
  },
  GT: {
    name: "Guatemala",
    code: "502",
  },
  GS: {
    name: "South Georgia and the South Sandwich Islands",
    code: "500",
  },
  GR: {
    name: "Greece",
    code: "30",
  },
  GQ: {
    name: "Equatorial Guinea",
    code: "240",
  },
  GP: {
    name: "Guadeloupe",
    code: "590",
  },
  JP: {
    name: "Japan",
    code: "81",
  },
  GY: {
    name: "Guyana",
    code: "592",
  },
  GG: {
    name: "Guernsey",
    code: "44",
  },
  GF: {
    name: "French Guiana",
    code: "594",
  },
  GE: {
    name: "Georgia",
    code: "995",
  },
  GD: {
    name: "Grenada",
    code: "1",
  },
  GB: {
    name: "United Kingdom",
    code: "44",
  },
  GA: {
    name: "Gabon",
    code: "241",
  },
  SV: {
    name: "El Salvador",
    code: "503",
  },
  GN: {
    name: "Guinea",
    code: "224",
  },
  GM: {
    name: "Gambia",
    code: "220",
  },
  GL: {
    name: "Greenland",
    code: "299",
  },
  GI: {
    name: "Gibraltar",
    code: "350",
  },
  GH: {
    name: "Ghana",
    code: "233",
  },
  OM: {
    name: "Oman",
    code: "968",
  },
  TN: {
    name: "Tunisia",
    code: "216",
  },
  JO: {
    name: "Jordan",
    code: "962",
  },
  HR: {
    name: "Croatia",
    code: "385",
  },
  HT: {
    name: "Haiti",
    code: "509",
  },
  HU: {
    name: "Hungary",
    code: "36",
  },
  HK: {
    name: "Hong Kong",
    code: "852",
  },
  HN: {
    name: "Honduras",
    code: "504",
  },
  HM: {
    name: "Heard Island and McDonald Islands",
    code: " ",
  },
  VE: {
    name: "Venezuela",
    code: "58",
  },
  PR: {
    name: "Puerto Rico",
    code: "1",
  },
  PS: {
    name: "Palestinian Territory",
    code: "970",
  },
  PW: {
    name: "Palau",
    code: "680",
  },
  PT: {
    name: "Portugal",
    code: "351",
  },
  SJ: {
    name: "Svalbard and Jan Mayen",
    code: "47",
  },
  PY: {
    name: "Paraguay",
    code: "595",
  },
  IQ: {
    name: "Iraq",
    code: "964",
  },
  PA: {
    name: "Panama",
    code: "507",
  },
  PF: {
    name: "French Polynesia",
    code: "689",
  },
  PG: {
    name: "Papua New Guinea",
    code: "675",
  },
  PE: {
    name: "Peru",
    code: "51",
  },
  PK: {
    name: "Pakistan",
    code: "92",
  },
  PH: {
    name: "Philippines",
    code: "63",
  },
  PN: {
    name: "Pitcairn",
    code: "870",
  },
  PL: {
    name: "Poland",
    code: "48",
  },
  PM: {
    name: "Saint Pierre and Miquelon",
    code: "508",
  },
  ZM: {
    name: "Zambia",
    code: "260",
  },
  EH: {
    name: "Western Sahara",
    code: "212",
  },
  EE: {
    name: "Estonia",
    code: "372",
  },
  EG: {
    name: "Egypt",
    code: "20",
  },
  ZA: {
    name: "South Africa",
    code: "27",
  },
  EC: {
    name: "Ecuador",
    code: "593",
  },
  IT: {
    name: "Italy",
    code: "39",
  },
  VN: {
    name: "Vietnam",
    code: "84",
  },
  SB: {
    name: "Solomon Islands",
    code: "677",
  },
  ET: {
    name: "Ethiopia",
    code: "251",
  },
  SO: {
    name: "Somalia",
    code: "252",
  },
  ZW: {
    name: "Zimbabwe",
    code: "263",
  },
  SA: {
    name: "Saudi Arabia",
    code: "966",
  },
  ES: {
    name: "Spain",
    code: "34",
  },
  ER: {
    name: "Eritrea",
    code: "291",
  },
  ME: {
    name: "Montenegro",
    code: "382",
  },
  MD: {
    name: "Moldova",
    code: "373",
  },
  MG: {
    name: "Madagascar",
    code: "261",
  },
  MF: {
    name: "Saint Martin",
    code: "590",
  },
  MA: {
    name: "Morocco",
    code: "212",
  },
  MC: {
    name: "Monaco",
    code: "377",
  },
  UZ: {
    name: "Uzbekistan",
    code: "998",
  },
  MM: {
    name: "Myanmar",
    code: "95",
  },
  ML: {
    name: "Mali",
    code: "223",
  },
  MO: {
    name: "Macao",
    code: "853",
  },
  MN: {
    name: "Mongolia",
    code: "976",
  },
  MH: {
    name: "Marshall Islands",
    code: "692",
  },
  MK: {
    name: "Macedonia",
    code: "389",
  },
  MU: {
    name: "Mauritius",
    code: "230",
  },
  MT: {
    name: "Malta",
    code: "356",
  },
  MW: {
    name: "Malawi",
    code: "265",
  },
  MV: {
    name: "Maldives",
    code: "960",
  },
  MQ: {
    name: "Martinique",
    code: "596",
  },
  MP: {
    name: "Northern Mariana Islands",
    code: "1",
  },
  MS: {
    name: "Montserrat",
    code: "1",
  },
  MR: {
    name: "Mauritania",
    code: "222",
  },
  IM: {
    name: "Isle of Man",
    code: "44",
  },
  UG: {
    name: "Uganda",
    code: "256",
  },
  TZ: {
    name: "Tanzania",
    code: "255",
  },
  MY: {
    name: "Malaysia",
    code: "60",
  },
  MX: {
    name: "Mexico",
    code: "52",
  },
  IL: {
    name: "Israel",
    code: "972",
  },
  FR: {
    name: "France",
    code: "33",
  },
  IO: {
    name: "British Indian Ocean Territory",
    code: "246",
  },
  SH: {
    name: "Saint Helena",
    code: "290",
  },
  FI: {
    name: "Finland",
    code: "358",
  },
  FJ: {
    name: "Fiji",
    code: "679",
  },
  FK: {
    name: "Falkland Islands",
    code: "500",
  },
  FM: {
    name: "Micronesia",
    code: "691",
  },
  FO: {
    name: "Faroe Islands",
    code: "298",
  },
  NI: {
    name: "Nicaragua",
    code: "505",
  },
  NL: {
    name: "Netherlands",
    code: "31",
  },
  NO: {
    name: "Norway",
    code: "47",
  },
  NA: {
    name: "Namibia",
    code: "264",
  },
  VU: {
    name: "Vanuatu",
    code: "678",
  },
  NC: {
    name: "New Caledonia",
    code: "687",
  },
  NE: {
    name: "Niger",
    code: "227",
  },
  NF: {
    name: "Norfolk Island",
    code: "672",
  },
  NG: {
    name: "Nigeria",
    code: "234",
  },
  NZ: {
    name: "New Zealand",
    code: "64",
  },
  NP: {
    name: "Nepal",
    code: "977",
  },
  NR: {
    name: "Nauru",
    code: "674",
  },
  NU: {
    name: "Niue",
    code: "683",
  },
  CK: {
    name: "Cook Islands",
    code: "682",
  },
  XK: {
    name: "Kosovo",
    code: "383",
  },
  CI: {
    name: "Ivory Coast",
    code: "225",
  },
  CH: {
    name: "Switzerland",
    code: "41",
  },
  CO: {
    name: "Colombia",
    code: "57",
  },
  CN: {
    name: "China",
    code: "86",
  },
  CM: {
    name: "Cameroon",
    code: "237",
  },
  CL: {
    name: "Chile",
    code: "56",
  },
  CC: {
    name: "Cocos Islands",
    code: "61",
  },
  CA: {
    name: "Canada",
    code: "1",
  },
  CG: {
    name: "Republic of the Congo",
    code: "242",
  },
  CF: {
    name: "Central African Republic",
    code: "236",
  },
  CD: {
    name: "Democratic Republic of the Congo",
    code: "243",
  },
  CZ: {
    name: "Czech Republic",
    code: "420",
  },
  CY: {
    name: "Cyprus",
    code: "357",
  },
  CX: {
    name: "Christmas Island",
    code: "61",
  },
  CR: {
    name: "Costa Rica",
    code: "506",
  },
  CW: {
    name: "Curacao",
    code: "599",
  },
  CV: {
    name: "Cape Verde",
    code: "238",
  },
  CU: {
    name: "Cuba",
    code: "53",
  },
  SZ: {
    name: "Swaziland",
    code: "268",
  },
  SY: {
    name: "Syria",
    code: "963",
  },
  SX: {
    name: "Sint Maarten",
    code: "599",
  },
  KG: {
    name: "Kyrgyzstan",
    code: "996",
  },
  KE: {
    name: "Kenya",
    code: "254",
  },
  SS: {
    name: "South Sudan",
    code: "211",
  },
  SR: {
    name: "Suriname",
    code: "597",
  },
  KI: {
    name: "Kiribati",
    code: "686",
  },
  KH: {
    name: "Cambodia",
    code: "855",
  },
  KN: {
    name: "Saint Kitts and Nevis",
    code: "1",
  },
  KM: {
    name: "Comoros",
    code: "269",
  },
  ST: {
    name: "Sao Tome and Principe",
    code: "239",
  },
  SK: {
    name: "Slovakia",
    code: "421",
  },
  KR: {
    name: "South Korea",
    code: "82",
  },
  SI: {
    name: "Slovenia",
    code: "386",
  },
  KP: {
    name: "North Korea",
    code: "850",
  },
  KW: {
    name: "Kuwait",
    code: "965",
  },
  SN: {
    name: "Senegal",
    code: "221",
  },
  SM: {
    name: "San Marino",
    code: "378",
  },
  SL: {
    name: "Sierra Leone",
    code: "232",
  },
  SC: {
    name: "Seychelles",
    code: "248",
  },
  KZ: {
    name: "Kazakhstan",
    code: "7",
  },
  KY: {
    name: "Cayman Islands",
    code: "1",
  },
  SG: {
    name: "Singapore",
    code: "65",
  },
  SE: {
    name: "Sweden",
    code: "46",
  },
  SD: {
    name: "Sudan",
    code: "249",
  },
  DO: {
    name: "Dominican Republic",
    code: "1",
  },
  DM: {
    name: "Dominica",
    code: "1",
  },
  DJ: {
    name: "Djibouti",
    code: "253",
  },
  DK: {
    name: "Denmark",
    code: "45",
  },
  VG: {
    name: "British Virgin Islands",
    code: "1",
  },
  DE: {
    name: "Germany",
    code: "49",
  },
  YE: {
    name: "Yemen",
    code: "967",
  },
  DZ: {
    name: "Algeria",
    code: "213",
  },
  US: {
    name: "United States",
    code: "1",
  },
  UY: {
    name: "Uruguay",
    code: "598",
  },
  YT: {
    name: "Mayotte",
    code: "262",
  },
  UM: {
    name: "United States Minor Outlying Islands",
    code: "1",
  },
  LB: {
    name: "Lebanon",
    code: "961",
  },
  LC: {
    name: "Saint Lucia",
    code: "1",
  },
  LA: {
    name: "Laos",
    code: "856",
  },
  TV: {
    name: "Tuvalu",
    code: "688",
  },
  TW: {
    name: "Taiwan",
    code: "886",
  },
  TT: {
    name: "Trinidad and Tobago",
    code: "1",
  },
  TR: {
    name: "Turkey",
    code: "90",
  },
  LK: {
    name: "Sri Lanka",
    code: "94",
  },
  LI: {
    name: "Liechtenstein",
    code: "423",
  },
  LV: {
    name: "Latvia",
    code: "371",
  },
  TO: {
    name: "Tonga",
    code: "676",
  },
  LT: {
    name: "Lithuania",
    code: "370",
  },
  LU: {
    name: "Luxembourg",
    code: "352",
  },
  LR: {
    name: "Liberia",
    code: "231",
  },
  LS: {
    name: "Lesotho",
    code: "266",
  },
  TH: {
    name: "Thailand",
    code: "66",
  },
  TF: {
    name: "French Southern Territories",
    code: "262",
  },
  TG: {
    name: "Togo",
    code: "228",
  },
  TD: {
    name: "Chad",
    code: "235",
  },
  TC: {
    name: "Turks and Caicos Islands",
    code: "1",
  },
  LY: {
    name: "Libya",
    code: "218",
  },
  VA: {
    name: "Vatican",
    code: "379",
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    code: "1",
  },
  AE: {
    name: "United Arab Emirates",
    code: "971",
  },
  AD: {
    name: "Andorra",
    code: "376",
  },
  AG: {
    name: "Antigua and Barbuda",
    code: "1",
  },
  AF: {
    name: "Afghanistan",
    code: "93",
  },
  AI: {
    name: "Anguilla",
    code: "1",
  },
  VI: {
    name: "U.S. Virgin Islands",
    code: "1",
  },
  IS: {
    name: "Iceland",
    code: "354",
  },
  IR: {
    name: "Iran",
    code: "98",
  },
  AM: {
    name: "Armenia",
    code: "374",
  },
  AL: {
    name: "Albania",
    code: "355",
  },
  AO: {
    name: "Angola",
    code: "244",
  },
  AQ: {
    name: "Antarctica",
    code: "672",
  },
  AS: {
    name: "American Samoa",
    code: "1",
  },
  AR: {
    name: "Argentina",
    code: "54",
  },
  AU: {
    name: "Australia",
    code: "61",
  },
  AT: {
    name: "Austria",
    code: "43",
  },
  AW: {
    name: "Aruba",
    code: "297",
  },
  IN: {
    name: "India",
    code: "91",
  },
  AX: {
    name: "Aland Islands",
    code: "358",
  },
  AZ: {
    name: "Azerbaijan",
    code: "994",
  },
  IE: {
    name: "Ireland",
    code: "353",
  },
  ID: {
    name: "Indonesia",
    code: "62",
  },
  UA: {
    name: "Ukraine",
    code: "380",
  },
  QA: {
    name: "Qatar",
    code: "974",
  },
  MZ: {
    name: "Mozambique",
    code: "258",
  },
}
