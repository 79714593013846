import React from "react"
import styles from "./languageFooter.module.scss"
import globalStyles from "../services/globalStyles.module.scss"

const LanguageFooter = () => {
  const languages = [
    { value: "ENGLISH", code: "en" },
    { value: "ČESKY", code: "cs" },
    { value: "DANSK", code: "da" },
    { value: "DEUTSCH", code: "de" },
    { value: "ESPAÑOL", code: "es" },
    { value: "ΕΛΛΗΝΙΚΆ", code: "el" },
    { value: "FRANÇAIS", code: "fr" },
    { value: "ITALIANO", code: "it" },
    { value: "עִבְרִית‎", code: "he" },
    { value: "MAGYAR", code: "hu" },
    { value: "BAHASA MELAYU", code: "ms" },
    { value: "NEDERLANDS", code: "nl" },
    { value: "NORSK", code: "no" },
    { value: "POLSZCZYZNA", code: "pl" },
    { value: "PORTUGUÊS", code: "pt" },
    { value: "РУССКИЙ ЯЗЫК", code: "ru" },
    { value: "SUOMI", code: "fi" },
    { value: "SVENSKA", code: "sv" },
    { value: "УКРАЇНСЬКА", code: "uk" },
    { value: "简体中文", code: "zh" },
    { value: "日本語", code: "ja"},
  ]

  return (
    <div
      className={[
        styles.container,
        globalStyles.contentContainer140,
        globalStyles.contentContainerMobile20,
      ].join(" ")}
    >
      <div className={styles.content}>
        {languages.map((val, index) => {
          return (
            <a
              key={index}
              className={styles.language}
              href={`/${val.code === "en" ? "" : val.code}`}
            >
              {val.value}
            </a>
          )
        })}
      </div>
    </div>
  )
}

LanguageFooter.propTypes = {}

export default LanguageFooter
