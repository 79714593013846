import React from "react"
 import PropTypes from "prop-types"

import styles from "./message.module.scss"

const Message = ({title, subtitle}) => {
  return <div className={styles.content}>
    <h1 className={styles.title}>{title}</h1>
    <p className={styles.subtitle}>{subtitle}</p>
  </div>
}

Message.propTypes = {

}


export default Message
