import React from "react"
import PropTypes from "prop-types"
import styles from "./socialMediaNavLink.module.scss"

const SocialMediaNavLink = ({ icon, title, route }) => {

  return (
    <div>
      <a style={{ textDecoration: "none" }} href={route} target="_blank">
        <div className={styles.navFooterItem}>
          <div className={styles.cell}>
            {icon ?
              <img style={{ width: 11, height: 11, margin: 0, marginRight: 10 }} src={icon} alt="" />
              : <div style={{ width: 11, height: 11, margin: 0, marginRight: 10 }}/>
            }
          </div>
          <div className={[styles.cell, styles.text].join(' ')}>{title.toUpperCase()}</div>
        </div>
      </a>
    </div>
  )
}

SocialMediaNavLink.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  route: PropTypes.string,
}

export default SocialMediaNavLink
