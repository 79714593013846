import React from 'react'
import closeIcon from "../images/common/close_icon.svg"
import style from './closeButton.module.scss'


const CloseButton = ({onClick}) => (
    <button className={style.closeButton} onClick={onClick}>
        <img src={closeIcon} alt="close" />
    </button>
)

export default CloseButton