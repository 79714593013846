import { navigate } from "gatsby"

export const openModal = () => {
  const url = new URL(location.href)
  if (url.searchParams.get("modal") !== "open") {
    url.searchParams.append("modal", "open")
    navigate(url.search, {
      replace: true,
      state: {
        disableScrollUpdate: true,
      },
    })
  }
}

export const closeModal = () => {
  const url = new URL(location.href)
  if (url.searchParams.get("modal")) {
    url.searchParams.delete("modal")
    navigate(`${url.pathname}${url.search}`, {
      replace: true,
      state: {
        disableScrollUpdate: true,
      },
    })
  }
}
