import axios from "axios"
import Cookies from "js-cookie"

import { countries } from "./countries"
import { roles } from "./roles"

export const FREE_TRIAL_FORM_GUID = "9a766333-2900-4943-a7a2-597432b4b80e"
export const BOOK_A_DEMO_FORM_GUID = "e7a8a474-bde1-4fcb-b676-1a8fbd23914e"
export const SUPPORT_FORM_GUID = "122df79d-cb0e-4bca-ae46-bcac99a08c5c"
export const CONTACT_FORM_GUID = "5d2844c9-190e-4afb-bdc2-38be23edc435"

const ACCOUNT_ID = "27217931"

export const trackHubspotFormData = async (
  formGuid,
  formValues,
  trackSplittedName = true
) => {
  const [firstName, lastName] = (formValues.name ?? "").split(" ")

  await axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${ACCOUNT_ID}/${formGuid}`,
    {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: formValues.email,
        },
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: trackSplittedName ? firstName : formValues.name,
        },
        ...(trackSplittedName
          ? [
              {
                objectTypeId: "0-1",
                name: "lastname",
                value: lastName,
              },
            ]
          : []),
        ...(formValues.country
          ? [
              {
                objectTypeId: "0-1",
                name: "country",
                value: countries[formValues.country].name,
              },
            ]
          : []),
        ...(formValues.sport
          ? [
              {
                objectTypeId: "0-1",
                name: "sport",
                value: formValues.sport,
              },
            ]
          : []),
        ...(formValues.organization
          ? [
              {
                objectTypeId: "0-1",
                name: "organization_name",
                value: formValues.organization,
              },
            ]
          : []),
        ...(formValues.role !== undefined && !isNaN(Number(formValues.role))
          ? [
              {
                objectTypeId: "0-1",
                name: "role",
                value: roles[Number(formValues.role) - 1],
              },
            ]
          : []),
        ...(formValues.phone
          ? [
              {
                objectTypeId: "0-1",
                name: "phone",
                value: `${
                  formValues.country
                    ? `+${countries[formValues.country].code}`
                    : ""
                }${formValues.phone}`,
              },
            ]
          : []),
        ...(formValues.message
          ? [
              {
                objectTypeId: "0-1",
                name: "message",
                value: formValues.message,
              },
            ]
          : []),
      ],
      context: {
        hutk: Cookies.get("hubspotutk"),
        pageUri:
          window?.location?.hostname?.replace("wwww.", "") +
            window?.location?.pathname || "sidelinesports.com",
        pageName: "Sideline Sports",
      },
    }
  )
}
