import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "./layout.css"
import Context, { ContextProvider } from "./contextProvider"
import signupStyle from "./signup.module.scss"
import Cinema from "./cinema"
import Chat from "../components/chat"
import ogImage from "../images/og-image-2.jpg"
import TryForFreeModal from "./tryForFreeModal"

const PAGES_WITHOUT_TRACKINGS = ['privacyPolicy', 'dpa', 'legal', 'tou']

const Layout = ({
  children,
  header,
  translations,
  customMeta,
  languageCode,
  addBlogPostContentType = false,
  removeScript = false,
}) => {
  const [isPageWithoutAnalytics, setIsPageWithoutAnalytics] = useState(true)
  const [
    shouldSkipAnalyticsInitialization,
    setShouldSkipAnalyticsInitialization,
  ] = useState(true)

  useEffect(() => {
    const url = new URL(window.location)
    const ccd = url.host
    const pathname = url.pathname

    const isPageWithoutAnalytics = PAGES_WITHOUT_TRACKINGS.some(page => pathname.includes(page))

    const shouldSkipAnalyticsInitialization =
      (ccd.includes("xpsnetwork.com") && removeScript) || isPageWithoutAnalytics

    setIsPageWithoutAnalytics(isPageWithoutAnalytics)
    setShouldSkipAnalyticsInitialization(shouldSkipAnalyticsInitialization)

    // add cookies spravne script + facebook domain verificaion
    if (shouldSkipAnalyticsInitialization) {
      return
    }

    const ccdk = {
      "sidelinesports.com": {
        cookiesKey: "n5dM8ap24sbUdHs56dbw",
        fbKey: "edvnzcbucot80myqhdurdzl8cqvl8d",
      },
      "www.sidelinesports.com": {
        cookiesKey: "n5dM8ap24sbUdHs56dbw",
        fbKey: "edvnzcbucot80myqhdurdzl8cqvl8d",
      },
      "xpsnetwork.com": {
        cookiesKey: "q2cmtfIjzdW90KbZINT4",
        fbKey: "ftxrsp0roee3xayqy95f5om1q9xkui",
      },
      "www.xpsnetwork.com": {
        cookiesKey: "q2cmtfIjzdW90KbZINT4",
        fbKey: "ftxrsp0roee3xayqy95f5om1q9xkui",
      },
    }
    const cookiesScript = document.createElement("script")
    cookiesScript.id = "cookies-spravne"
    cookiesScript.type = "text/javascript"
    cookiesScript.setAttribute("async", "")
    cookiesScript.setAttribute("defer", "")

    const fbMeta = document.createElement("meta")
    fbMeta.name = "facebook-domain-verification"

    if (ccdk[ccd]) {
      cookiesScript.src =
        "https://cookies-spravne.cz/static/cc?key=" + ccdk[ccd].cookiesKey
      fbMeta.content = ccdk[ccd].fbKey
    }

    document.head.appendChild(cookiesScript)
    document.head.appendChild(fbMeta)

    return () => {
      document.head.removeChild(cookiesScript)
      document.head.removeChild(fbMeta)
    }
  }, [shouldSkipAnalyticsInitialization])

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <ContextProvider>
          <Helmet>
            <title>{customMeta?.title || data.site.siteMetadata.title}</title>
            <meta
              name="title"
              content={
                customMeta?.title ||
                "XPS Network — for the love of developing athletes"
              }
            />
            <meta
              name="description"
              content={
                customMeta?.description ||
                "XPS Network is the essential coaching platform focusing on the key elements of Planning, Analysis and Communication"
              }
            />

            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content={
                customMeta?.title ||
                "XPS Network — for the love of developing athletes"
              }
            />
            <meta
              property="og:description"
              content={
                customMeta?.description ||
                "XPS Network is the essential coaching platform focusing on the key elements of Planning, Analysis and Communication"
              }
            />
            <meta property="og:image" content={customMeta?.image || ogImage} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:title"
              content={
                customMeta?.title ||
                "XPS Network — for the love of developing athletes"
              }
            />
            <meta
              property="twitter:description"
              content={
                customMeta?.description ||
                "XPS Network is the essential coaching platform focusing on the key elements of Planning, Analysis and Communication"
              }
            />
            <meta
              property="twitter:image"
              content={customMeta?.image || ogImage}
            />

            <link
              rel="preload"
              href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css"
              as="style"
              onload="this.rel='stylesheet'"
            />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossorigin
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap"
              rel="stylesheet"
            />

            {addBlogPostContentType && (
              <script>
                var _hsq = window._hsq = window._hsq || [];
                _hsq.push(['setContentType', 'blog-post']);
              </script>
            )}

            {!isPageWithoutAnalytics && (
              <script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src="//js-eu1.hs-scripts.com/27217931.js"
              ></script>
            )}

            {!shouldSkipAnalyticsInitialization && (
              <script
                data-host="https://analytikawebu.cz"
                data-dnt="false"
                src="https://analytikawebu.cz/js/script.js"
                id="ZwSg9rf6GA"
                async
                defer
              ></script>
            )}
            <script>
              // Set global language code variable for signup form var
              XPSSignupLanguage = "en";
            </script>
          </Helmet>
          <main id="body">{children}</main>
          <Context.Consumer>
            {(data) => {
              return (
                <Cinema
                  static
                  show={data.data.signUpOpen}
                  hideOverlay={() => data.set({ signUpOpen: false })}
                >
                  <div
                    id="sign-up-container"
                    className={signupStyle.signUpContainer}
                  />
                </Cinema>
              )
            }}
          </Context.Consumer>
          <Context.Consumer>
            {(data) => {
              return (
                <Cinema
                  static
                  show={data.data.cinemaOpen}
                  hideOverlay={() => {
                    data.set({ cinemaOpen: false })
                    data.data.cinemaOnClose && data.data.cinemaOnClose()
                  }}
                >
                  {data.data.cinemaContent}
                </Cinema>
              )
            }}
          </Context.Consumer>
          <Chat translations={translations} />
          <TryForFreeModal
            languageCode={languageCode}
            translations={translations.try_xps_modal}
            globalTranslations={translations.by}
          />
          {header}
        </ContextProvider>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
