export const roles = [
  "Athletic Director",
  "Academy/High School/College Coach",
  "Club Coach/Manager",
  "Youth Coach",
  "Strength & Conditioning Coach",
  "Personal Trainer",
  "Gym/Studio Manager or Owner",
  "Athlete or Client",
]
