export class LanguageService {
  static languageSelected = false;
}

export function setNewLanguage(language) {
  LanguageService.languageSelected = true;
  document.cookie = 'language=;path=/; expires=-1;';

  document.cookie = `language=${language};path=/`;
  if(language != "en"){
    document.location.href = `${document.location.origin}/${language}`;
  } else {
    document.location.href = document.location.origin
  }
}

export const langPath = (languageCode) => (path) => {
  if (!path.startsWith('/')) return path;

  if (languageCode === 'en') {
    return path;
  } else {
    return `/${languageCode}${path}`;
  }
}
