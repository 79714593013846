// Context.js
import React from "react"

const defaultContextValue = {
  data: {
    // set your initial data shape here
    signUpOpen: false,
    signUpInit: false,
    blur: false,
    resizing: false,
    cinemaOpen: false,
    cinemaContent: <div/>,
    cinemaOnClose: ()=>{},
    isMobile: undefined
  },
  set: () => {},
}

const Context = React.createContext(defaultContextValue)

class ContextProvider extends React.Component {
  constructor() {
    super()

    this.setCinemaData = this.setCinemaData.bind(this)
    this.setSignUpData = this.setSignUpData.bind(this)
    this.setFocus = this.setFocus.bind(this)
    this.setResize = this.setResize.bind(this)
    this.timedUndoResize = this.timedUndoResize.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setSignUpData,
      setCinema: this.setCinemaData
    }
  }

  componentDidMount(){
    this.setState(state => ({
      data: {
        ...state.data,
        isMobile: window.innerWidth <= 824,
        isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
        isIE: !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g)
      },
    }));
    window.addEventListener("focus", this.setFocus);
    window.addEventListener("blur", this.setFocus);
    window.addEventListener('resize', this.setResize);
  }
  componentWillUnmount(){
    window.removeEventListener("focus", this.setFocus);
    window.removeEventListener("blur", this.setFocus);
    window.removeEventListener('resize', this.setResize);
  }

  timedUndoResize(){
    this.setState(state => ({
      data: {
        ...state.data,
        resize: false
      },
    }))
  }

  setResize(e){
    this.setState(state => ({
      data: {
        ...state.data,
        isMobile: window.innerWidth <= 824,
        resize: true
      },
    }));

    this.resizeTimer && clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(this.timedUndoResize, 250);
  }

  setFocus(e){
    this.setState(state => ({
      data: {
        ...state.data,
        blur: e.type === "blur"
      },
    }))
  }

  signUp() {
    const getStarted = document.getElementById('sign-up-container');
    XPSSignup.render(getStarted, function () {
      window.location = '/downloads/'
    });
  }


  setSignUpData(newData) {
    this.signUp();

    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }))
  }

  setCinemaData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData
      }
    }))
  }

  render() {
    return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>
  }
}

export { Context as default, ContextProvider }