import React from "react"
import styles from "./cinema.module.scss"

class Cinema extends React.Component {
  constructor(props) {
    super(props);
    this.hideOverlay = this.hideOverlay.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    if (!this.props.static) {
      this.show();
    }
  }
  componentWillUnmount() {
    if (!this.props.static) {
      this.hide({target: {id: "cinema"}});
    }
  }

  componentWillReceiveProps(newProps){
    if(newProps.show && !this.props.show ){
      this.show();
    } else if(!newProps.show && this.props.show){
      this.hide({target: {id: "cinema"}});
    }
  }

  show(){
    document.documentElement.style.overflow = 'hidden';
    this.refs.cinemaContainer.style.display = this.props.display ? this.props.display : "flex";
  }
  hide(e) {
    if(e && e.target.id != "cinema"){
      return;
    }
    document.documentElement.style.overflow = 'initial';
    this.refs.cinemaContainer.style.display = "none";

    this.props.hideOverlay && this.props.hideOverlay();
  }

  hideOverlay(e) {
    this.props.hideOverlay();
  }

  render() {
    const { children, className } = this.props;
    return (
      <div ref="cinemaContainer" id="cinema" onClick={!this.props.static ? this.hideOverlay : this.hide} className={[styles.cinemaContainer, className].join(' ')}>
        {children}
      </div>)
  }
}


export default Cinema
