import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styles from "./button.module.scss"
import Cinema from "./cinema"
import Message from "./message"
import Context from "./contextProvider"

const Button = ({
  children,
  classStyle,
  onClick,
  type,
  anchor,
  showMessage,
  icon,
  hideOverlay,
  cinema,
  disabled,
  target,
  light,
}) => {
  const procedure = (set) => {
    set({
      cinemaOpen: true,
      cinemaContent: cinema,
      cinemaOnClose: hideOverlay,
    })
  }

  return (
    <Context.Consumer>
      {({ data, setCinema }) =>
        anchor ? (
          <a
            href={anchor}
            target={target}
            className={`${styles.button} ${classStyle} ${
              light ? styles.light : ""
            }`}
            type={type}
            onClick={() => {
              cinema && procedure(setCinema)
              onClick && onClick()
            }}
          >
            {children}
          </a>
        ) : (
          <button
            onClick={() => {
              cinema && procedure(setCinema)
              onClick && onClick()
            }}
            className={`${styles.button} ${classStyle} ${
              light ? styles.light : ""
            }`}
            type={type}
            disabled={disabled}
          >
            <div className={styles.textWrapper}>{children}</div>
            {icon && (
              <div className={styles.iconWrapper}>
                <img src={icon} alt="icon" />
              </div>
            )}
          </button>
        )
      }
    </Context.Consumer>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  classStyle: PropTypes.string,
}

export default Button
