import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import logo from "../images/logoBlackAndWhite.svg"
import fbIcon from "../images/common/facebook.svg"
import instaIcon from "../images/common/instagram.svg"
import twitterIcon from "../images/common/twitter.svg"
import linkedinIcon from "../images/common/linkedin.svg"

import SocialMediaNavLink from "./socialMediaNavLink"

import styles from "./navFooter.module.scss"
import { langPath } from "../services/languageService"

const NavFooter = ({ translations, data, languageCode }) => {
  const { sportContent } = data;
  const link = langPath(languageCode);

  return (
    <div className={styles.wrap}>
      <div className={styles.logoTitle}>
        <Link to={link('/')}>
          <img src={logo} className={styles.logo} alt="XPS Network" />
        </Link>
      </div>

      <div className={styles.navContainer}>
        {/* SPORTS */}
        <div className={[styles.sportsSection, styles.section].join(" ")}>
          <h2 className={styles.title}> {translations.sports_title?.toUpperCase()}  </h2>
          {sportContent.edges.sort((a, b) => a.node.frontmatter.title.localeCompare(b.node.frontmatter.title)).map((val, index) => {
            if (val.node.frontmatter.title === 'Other') return null;
            const sportPath = val.node.frontmatter.title.replace(' ', '');
            return (
              <div key={index} className={styles.sportsSectionItem}>
                <Link className={[styles.linkStyles].join(" ")} to={link(`/sport/?type=${sportPath}`)} state={{ sport: val.node.frontmatter.title.replace(' ', '') }}>
                  {translations.sports[val.node.frontmatter.title.toLowerCase().replace(' ', '_')]?.toUpperCase()}
                </Link>
              </div>
            )
          })}
          <div className={styles.sportsSectionItem}>
            <Link className={[styles.linkStyles].join(" ")} to={link(`/sport/?type=Other`)} state={{ sport: 'Other' }}>
              {translations.sports['other_sports']?.toUpperCase()}
            </Link>
          </div>
        </div>

        {/* EXPLORE */}

        <div className={[styles.exploreSection, styles.section].join(" ")}>
          <h2 style={{ flex: 1 }} className={styles.title}> {translations.explore?.toUpperCase()} </h2>
          <div className={styles.exploreSectionItem}>
            <Link className={[styles.linkStyles, styles.exploreSectionItem].join(" ")} to={link('/customers')}>
              {translations.customers_title?.toUpperCase()}
            </Link>
          </div>
          <div className={styles.exploreSectionItem}>
            <Link className={[styles.linkStyles, styles.exploreSectionItem].join(" ")} to={link('/testimonials')}>
              {translations.testimonials_title?.toUpperCase()}
            </Link>
          </div>
          <div className={styles.exploreSectionItem}>
            <a className={[styles.linkStyles, styles.exploreSectionItem].join(" ")} href={`https://sidelinesports.com/${languageCode === 'cs' ? 'cs/' : ''}blog`} target="_blank">
              {translations.blog_title?.toUpperCase()}
            </a>
          </div>
          <div className={styles.exploreSectionItem}>
            <Link className={[styles.linkStyles, styles.exploreSectionItem].join(" ")} to={link('/contact')} >
              {translations.contact_title?.toUpperCase()}
            </Link>
          </div>
          <div className={styles.exploreSectionItem}>
            <Link className={[styles.linkStyles, styles.exploreSectionItem].join(" ")} to={link('/about')} >
              {translations.about_us_title?.toUpperCase()}
            </Link>
          </div>
        </div>

        {/* RESOURCE */}
        <div className={[styles.resourceSection, styles.section].join(" ")}>
          <h2 style={{ flex: 1 }} className={styles.title}>
            {translations.resources_title?.toUpperCase()}
          </h2>

          <div className={styles.resourceSectionItem}>
            <Link className={styles.linkStyles} to={link('/support')} >
              {translations.support_title?.toUpperCase()}
            </Link>
          </div>
          <div className={styles.resourceSectionItem}>
            <Link className={styles.linkStyles} to={link('/downloads')}>
              {translations.downloads_title?.toUpperCase()}
            </Link>
          </div>
          <div className={styles.resourceSectionItem}>
            <Link className={styles.linkStyles} to={link('/legal')} >
              {translations.legal_title?.toUpperCase()}
            </Link>
          </div>
          <div className={styles.resourceSectionItem}>
            <Link className={styles.linkStyles} to={link('/socialMedia')} >
              {translations.social_media_title?.toUpperCase()}
            </Link>
          </div>
        </div>
        <div className={[styles.socialSection, styles.section].join(" ")}>
          <div className={styles.socialSectionItem}>
            <SocialMediaNavLink route={"https://www.facebook.com/sidelinesports/"} title={"facebook"} icon={fbIcon} />
          </div>
          <div className={styles.socialSectionItem}>
            <SocialMediaNavLink
              route={"https://twitter.com/xpsnetwork"}
              title={"twitter"}
              icon={twitterIcon}
            />
          </div>
          <div className={styles.socialSectionItem}>
            <SocialMediaNavLink
              route={"https://www.instagram.com/xpsnetwork/"}
              title={"instagram"}
              icon={instaIcon}
            />
          </div>
          <div className={styles.socialSectionItem}>
            <SocialMediaNavLink route={"https://www.linkedin.com/company/sideline-sports"}
            title={"linkedin"}
            icon={linkedinIcon} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default props => (
  <StaticQuery
    query={graphql`
      query {
        sportContent: allMarkdownRemark(filter: { fileAbsolutePath: { regex:"\/sportsContent/" } }) {
          edges {
            node {
              frontmatter {
                title
                icon{
                  name
                  extension
                  relativePath
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={data => <NavFooter data={data} {...props} />}
  />
)
